export const packagesData = [
  {
    id: 1,
    label: "RELIGIOUS",
    category: "RELIGIOUS",
    img: "BUDDHIST_CIRCLE.jpg",
    packagesTitle: "3 packages",
    onHomePage: true,
    title: "RELIGIOUS",
    subTitle: "The Best Destination",
    isVisible: true,
    isComming: false,
    packages: [
      {
        id: 1,
        label: "COMPLETE BUDDHIST CIRCUIT",
        img: "BUDDHIST_CIRCLE.jpg",
        title: "COMPLETE BUDDHIST CIRCUIT",
        subTitle: "A Journey for Peaceful way of Life",
        date: "8 November 2024",
        endDate: "19 November 2024",
        duration: "11 Days & 12 Nights",
        downloadLink:
          "https://drive.google.com/file/d/1ufgBufWKpLUyivwzqZkHrz0M591PmvRn/view?usp=sharing",
        location: "Bodhgaya, Bihar",
        price: "23,499",
        productCode: "BODHGAYA01081122",
        note: [
          {
            label:
              "Package cost ₹23,499/- is available for Indian Nationals only",
          },
        ],
        isCstomized: false,
        cancellationPolicy: [
          { label: "Replacement of your seat is first option." },
          {
            label:
              "Bookings made less than 15 days before the tour starts are non-refundable.",
          },
          {
            label:
              "If cancelled 07 days before the tour starts – Total cost will be charged.",
          },
          {
            label:
              "If cancelled 15 days before the tour starts – 50% of Total cost will be charged.",
          },
          {
            label:
              "If cancelled 30 days before the tour starts – 35% of Total cost will be charged.",
          },
          {
            label:
              "If cancelled 60 days before the tour starts – 25% of Total cost will be charged.",
          },
          {
            label:
              "No Refund in case of early termination of tour by any tourist.",
          },
        ],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done OR Partly package cost i.e. ₹ 10,000/- payment should be done & remaining ₹13,499/- should be paid before 6th October 2024.",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        isVisible: true,
        isComming: false,
        inclusions: [
          {
            label:
              "AC Semi Sleeper Air Suspension Travels for complete circuit",
          },
          { label: "Accommodation at 3 Star hotels & All Meals" },
          { label: "Tour operator & Guide" },
          { label: "Tourist travelling insurance" },
          { label: "Meet & Greet service by our Guest Experience at Bodhgaya" },
          { label: "Package cost is included with GST" },
          {
            label:
              "Breakfast, Lunch & Dinner will be provided from Bodhgaya, Bihar",
          },
          { label: "All places entry tickets" },
          {
            label:
              "Guide service will be provided at various sightseeing locations",
          },
          { label: "First aid is available during entire package" },
        ],
        dayByDay: [
          {
            label: "Day 1: November 8th 2024, Tuesday (Kartik Purnima)",
            location: "Bodhgaya",
            description: [
              {
                label:
                  "Reporting and check in at 07.00am and Breakfast at hotel.",
              },
              { label: "Visit at Mahabodhi temple." },
              { label: "Lunch at hotel & proceed for Bodhgaya Sightseeing." },
              { label: "Dinner and overnight stay at the hotel." },
            ],
          },
          {
            label: "Day 2: November 9th 2024, Wednesday",
            location: "Bodhgaya-Rajgir-Nalanda-Bodhgaya",
            description: [
              {
                label:
                  "Getting fresh take Breakfast at hotel & proceed for Rajgir.",
              },
              { label: "Sightseeing at Rajgir." },
              { label: "Lunch at Hotel in Rajgir & proceed for Nalanda," },
              {
                label: "Sightseeing at Nalanda & return to hotel in Bodhgaya.",
              },
              { label: "Dinner and overnight stay at the hotel." },
            ],
          },
          {
            label: "Day 3: November 10th 2024, Thursday",
            location: "Bodhgaya-Sarnath",
            description: [
              {
                label: "Getting fresh take Breakfast.",
              },
              { label: "Check out & proceed for Sarnath." },
              { label: "Check in at hotel in Sarnath." },
              { label: "Take Lunch & Sightseeing at Sarnath." },
              { label: "Dinner and overnight stay at the hotel." },
            ],
          },
          {
            label: "Day 4: November 11th 2024, Friday",
            location: "Sarnath-Lucknow",
            description: [
              {
                label: "Getting fresh take Breakfast.",
              },
              { label: "Check out & proceed for Lucknow" },
              { label: "Check in at hotel in Lucknow." },
              { label: "Take Lunch & Sightseeing at Lucknow." },
              { label: "Dinner and overnight stay at the hotel." },
            ],
          },
          {
            label: "Day 5: November 12th 2024, Saturday",
            location: "Lucknow-Shrawasti-Kapilvastu-Lumbini",
            description: [
              {
                label: "Getting fresh take Breakfast.",
              },
              { label: "Check out & proceed for Shrawasti" },
              { label: "Take Lunch at Shrawasti." },
              { label: "Take Lunch & Sightseeing at Lucknow." },
              { label: "Sightseeing & proceed for Kapilvastu." },
              { label: "Sightseeing at Kapilvastu & proceed for Lumbini." },
              { label: "Check in at hotel in Lumbini." },
              { label: "Dinner and overnight stay at the hotel." },
            ],
          },
          {
            label: "Day 6: November 13th 2024, Sunday",
            location: "Lumbini-Kushinagar",
            description: [
              {
                label: "Getting fresh take Breakfast.",
              },
              { label: "Sightseeing at Lumbini take Lunch." },
              { label: "Check out & proceed for Kushinagar." },
              { label: "Sightseeing at Kushinagar." },
              { label: "Check in at hotel in Kushinagar." },
              { label: "Dinner and overnight stay at there." },
            ],
          },
          {
            label: "Day 7: November 14th 2024, Monday",
            location: "Kushinagar-Vaishali-Patna",
            description: [
              {
                label: "Getting fresh take Breakfast and checkout.",
              },
              { label: "Sightseeing at Kushinagar and Proceed for Vaishali." },
              { label: "Take Lunch at hotel during this journey." },
              { label: "Sightseeing at Vaishali and proceed for Patna." },
              {
                label:
                  "Check in at hotel in Patna, Dinner and overnight stay at there.",
              },
            ],
          },
          {
            label: "Day 8: November 15th 2024, Tuesday",
            location: "Patna-Jehanabad-Bodhgaya",
            description: [
              {
                label: "Getting fresh take Breakfast and checkout.",
              },
              {
                label:
                  "Sightseeing at Patna, take Lunch and proceed for Jehanabad.",
              },
              { label: "Sightseeing at Jehanabad and proceed for Bodhgaya." },
              { label: "Check in at hotel in Bodhgaya." },
              { label: "Dinner and overnight stay at there." },
            ],
          },
          {
            label: "Day 9: November 16th 2024, Wednesday",
            location: "Bodhgaya-Dungeshwari-Bodhgaya",
            description: [
              {
                label:
                  "Getting fresh take Breakfast and proceed for Dungeshwari.",
              },
              { label: "Sightseeing at Dungeshwari take Lunch at there." },
              { label: "Proceed for Bodhgaya and Sightseeing in Bodhgaya." },
              { label: "Dinner and overnight stay at hotel." },
            ],
          },
          {
            label: "Day 10: November 17th 2024, Thursday",
            location: "Bodhgaya Local",
            description: [
              {
                label:
                  "Getting fresh take Breakfast and Sightseeing in Bodhgaya.",
              },
              { label: "Take Lunch and Sightseeing in Bodhgaya." },
              { label: "Dinner and overnight stay at hotel." },
            ],
          },
          {
            label: "Day 11: November 18th 2024, Friday",
            location: "Bodhgaya Local",
            description: [
              {
                label:
                  "Getting fresh take Breakfast and Sightseeing in Bodhgaya.",
              },
              { label: "Take Lunch at hotel or Monestry." },
              { label: "Dinner and overnight stay at hotel." },
            ],
          },
          {
            label: "Day 12: November 19th 2024, Saturday",
            location: "",
            description: [
              {
                label:
                  "After Breakfast enjoy morning, check out and tour completed....",
              },
            ],
          },
        ],
        description: {
          first:
            "The Buddhist Circuit is a trail of the sacred footsteps of Lord Buddha and important places of his life and teachings.",

          second:
            "The Buddhist circuit is a route that follows in the footsteps of the Buddha from Lumbini in Nepal where he was born, through Bodhgaya, Bihar in India where he attained enlightenment, to Sarnath and Kushinagar in Uttar Pradesh in India, where he gave his first teachings and his final resting place.",
        },
      },
      {
        id: 2,
        label: "CHARDHAM YATRA",
        img: "Chardham.jpg",
        title: "CHARDHAM YATRA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: false,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            location: "Bodhgaya",
            description: [
              {
                label:
                  "Reporting and check in at 07.00am and Breakfast at hotel.",
              },
              { label: "Visit at Mahabodhi temple." },
              { label: "Lunch at hotel & proceed for Bodhgaya Sightseeing." },
              { label: "Dinner and overnight stay at the hotel." },
            ],
          },
          {
            label: "DAY 2 - Departure at the airport and arrival at the hotel",
            location: "Bodhgaya",
            description: [
              {
                label:
                  "Reporting and check in at 07.00am and Breakfast at hotel.",
              },
              { label: "Visit at Mahabodhi temple." },
              { label: "Lunch at hotel & proceed for Bodhgaya Sightseeing." },
              { label: "Dinner and overnight stay at the hotel." },
            ],
          },
          {
            label: "DAY 3 - Departure at the airport and arrival at the hotel",
            location: "Bodhgaya",
            description: [
              {
                label:
                  "Reporting and check in at 07.00am and Breakfast at hotel.",
              },
              { label: "Visit at Mahabodhi temple." },
              { label: "Lunch at hotel & proceed for Bodhgaya Sightseeing." },
              { label: "Dinner and overnight stay at the hotel." },
            ],
          },
        ],
        description: {
          first: "",

          second: "",
        },
      },
      {
        id: 3,
        label: "KAILASH MANSAROVAR YATRA",
        img: "KAILASH_MANSAROVAR.jpg",
        title: "KAILASH MANSAROVAR YATRA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: false,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, ",
        },
      },
    ],
    description: {
      first: "",

      second: "",
    },
  },
  {
    id: 2,
    label: "NATIONAL TOURS",
    category: "NATIONAL TOURS",
    img: "ANDAMAN_NICOBAR.jpg",
    packagesTitle: "17 packages",
    onHomePage: true,
    title: "NATIONAL TOURS",
    subTitle: "The Best Destination",
    isVisible: true,
    isComming: false,
    packages: [
      {
        id: 1,
        label: "HIMACHAL SPECIAL",
        img: "HIMACHAL.jpg",
        title: "HIMACHAL SPECIAL",
        subTitle: "A Destination for all seasons and all reasons ",
        date: "8 December 2024",
        endDate: "18 December 2024",
        duration: "10 Days & 11 Nights",
        downloadLink:
          "https://drive.google.com/file/d/1vZcchCHc-woPUwbi-s0MMqmIyTGI7BC7/view?usp=sharing",
        location: "AURANGABAD, MAHARASHTRA",
        price: "22,499",
        productCode: "HIMACHAL01081222",
        note: [
          {
            label:
              "Package Cost ₹22,499/- Per Person on Twin/Triple Sharing basis.",
          },
          {
            label:
              "As the AC 3 Tier Train Reservation is considered book as early as possible for confirmation of tickets.",
          },
        ],
        isCstomized: false,
        cancellationPolicy: [
          { label: "Replacement of your seat is first option." },
          {
            label:
              "Bookings made less than 15 days before the tour starts are non-refundable.",
          },
          {
            label:
              "If cancelled 07 days before the tour starts - Total cost will be charged.",
          },
          {
            label:
              "If cancelled 15 days before the tour starts - 50% of Total cost will be charged.",
          },
          {
            label:
              "If cancelled 30 days before the tour starts - 35% of Total cost will be charged.",
          },
          {
            label:
              "If cancelled 60 days before the tour starts - 25% of Total cost will be charged.",
          },
          {
            label:
              "No Refund in case of early termination of tour by any tourist.",
          },
        ],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done OR Partly package cost i.e. ₹ 10,000/- payment should be done & remaining ₹12,499/- should be paid before 8th November 2024.",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label: "Meals during the train journey are not considered.",
          },
          {
            label: "Lunch is not included.",
          },
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        isVisible: true,
        isComming: false,
        inclusions: [
          {
            label:
              "Meet & Greet service by our Guest Experience at Aurangabad Station.",
          },
          { label: "Package cost is included with GST." },
          { label: "All transfers by Non AC Vehicle." },
          {
            label:
              "Aurangabad – Umbala & Ludhiana – Aurangabad AC 3 Tier Train Journey.",
          },
          {
            label:
              "Breakfast & Dinner will be provided from Shimla to Dharmshala. (MAP Basis)",
          },
          { label: "Tourist travelling insurance." },
          {
            label: "Tour operator",
          },
          { label: "First aid is available during entire package." },
        ],
        dayByDay: [
          {
            label: "Day 1: 8th December, 2024",
            location: "",
            description: [
              {
                label:
                  "Aurangabad (1.00pm) to Ambala. (AC 3 Tier Train Journey)",
              },
            ],
          },
          {
            label: "Day 2: 9th December, 2024",
            location: "",
            description: [
              {
                label: "Arrive at Ambala Cant. Station.(5.00pm)",
              },
              {
                label:
                  "Ambala Station to Shimla by road. (Dinner During Journey)",
              },
              { label: "Overnight stay at hotel in Shimla." },
            ],
          },
          {
            label: "Day 3: 10th December, 2024",
            location: "",
            description: [
              {
                label: "Shimla Sightseeing.",
              },
              { label: "Overnight stay at hotel in Shimla." },
            ],
          },
          {
            label: "Day 4: 11th December, 2024",
            location: "",
            description: [
              {
                label: "Shimla to Manali.",
              },
              { label: "Overnight stay at hotel in Manali" },
            ],
          },
          {
            label: "Day 5: 12th December, 2024",
            location: "",
            description: [
              {
                label: "Manali Sightseeing.",
              },
              { label: "Overnight stay at hotel in Manali." },
            ],
          },
          {
            label: "Day 6: 13th December, 2024",
            location: "",
            description: [
              {
                label: "Manali Sightseeing.",
              },
              { label: "Overnight stay at hotel in Manali." },
            ],
          },
          {
            label: "Day 7: 14th December, 2024",
            location: "",
            description: [
              {
                label: "Manali to Dharmshala. (Kullu)",
              },
              { label: "Overnight stay at hotel in Dharmshala." },
            ],
          },
          {
            label: "Day 8: 15th December, 2024",
            location: "",
            description: [
              {
                label: "Dharmshala Sightseeing.",
              },
              {
                label: "Overnight stay at hotel in Dharmshala.",
              },
            ],
          },
          {
            label: "Day 9: 16th December, 2024",
            location: "",
            description: [
              {
                label: "Dharmshala Sightseeing.",
              },
              { label: "After Dinner Night Journey to Ludhiana." },
            ],
          },
          {
            label: "Day 10: 17th December, 2024",
            location: "",
            description: [
              {
                label: "Ludhiana (7.30am) to Aurangabad.",
              },
            ],
          },
          {
            label: "Day 11: 18th December, 2024",
            location: "",
            description: [
              {
                label: "Arrive at Aurangabad (9.45am)",
              },
              { label: "Tour complete." },
            ],
          },
        ],
        description: {
          first:
            "The Buddhist Circuit is a trail of the sacred footsteps of Lord Buddha and important places of his life and teachings.",

          second:
            "The Buddhist circuit is a route that follows in the footsteps of the Buddha from Lumbini in Nepal where he was born, through Bodhgaya, Bihar in India where he attained enlightenment, to Sarnath and Kushinagar in Uttar Pradesh in India, where he gave his first teachings and his final resting place.",
        },
      },
      {
        id: 5,
        label: "ANDAMAN & NICOBAR",
        img: "ANDAMAN_NICOBAR.jpg",
        title: "ANDAMAN & NICOBAR",
        subTitle: "A Destination for all seasons and all reasons ",
        date: "8 December 2024",
        endDate: "18 December 2024",
        duration: "10 Days & 11 Nights",
        downloadLink:
          "https://drive.google.com/file/d/1vZcchCHc-woPUwbi-s0MMqmIyTGI7BC7/view?usp=sharing",
        location: "AURANGABAD, MAHARASHTRA",
        price: "22,499",
        productCode: "HIMACHAL01081222",
        note: [
          {
            label:
              "Package Cost ₹22,499/- Per Person on Twin/Triple Sharing basis.",
          },
          {
            label:
              "As the AC 3 Tier Train Reservation is considered book as early as possible for confirmation of tickets.",
          },
        ],
        isCstomized: false,
        cancellationPolicy: [
          { label: "Replacement of your seat is first option." },
          {
            label:
              "Bookings made less than 15 days before the tour starts are non-refundable.",
          },
          {
            label:
              "If cancelled 07 days before the tour starts - Total cost will be charged.",
          },
          {
            label:
              "If cancelled 15 days before the tour starts - 50% of Total cost will be charged.",
          },
          {
            label:
              "If cancelled 30 days before the tour starts - 35% of Total cost will be charged.",
          },
          {
            label:
              "If cancelled 60 days before the tour starts - 25% of Total cost will be charged.",
          },
          {
            label:
              "No Refund in case of early termination of tour by any tourist.",
          },
        ],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done OR Partly package cost i.e. ₹ 10,000/- payment should be done & remaining ₹12,499/- should be paid before 8th November 2024.",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label: "Meals during the train journey are not considered.",
          },
          {
            label: "Lunch is not included.",
          },
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        isVisible: true,
        isComming: true,
        inclusions: [
          {
            label:
              "Meet & Greet service by our Guest Experience at Aurangabad Station.",
          },
          { label: "Package cost is included with GST." },
          { label: "All transfers by Non AC Vehicle." },
          {
            label:
              "Aurangabad – Umbala & Ludhiana – Aurangabad AC 3 Tier Train Journey.",
          },
          {
            label:
              "Breakfast & Dinner will be provided from Shimla to Dharmshala. (MAP Basis)",
          },
          { label: "Tourist travelling insurance." },
          {
            label: "Tour operator",
          },
          { label: "First aid is available during entire package." },
        ],
        dayByDay: [
          {
            label: "Day 1: 8th December, 2024",
            location: "",
            description: [
              {
                label:
                  "Aurangabad (1.00pm) to Ambala. (AC 3 Tier Train Journey)",
              },
            ],
          },
          {
            label: "Day 2: 9th December, 2024",
            location: "",
            description: [
              {
                label: "Arrive at Ambala Cant. Station.(5.00pm)",
              },
              {
                label:
                  "Ambala Station to Shimla by road. (Dinner During Journey)",
              },
              { label: "Overnight stay at hotel in Shimla." },
            ],
          },
          {
            label: "Day 3: 10th December, 2024",
            location: "",
            description: [
              {
                label: "Shimla Sightseeing.",
              },
              { label: "Overnight stay at hotel in Shimla." },
            ],
          },
          {
            label: "Day 4: 11th December, 2024",
            location: "",
            description: [
              {
                label: "Shimla to Manali.",
              },
              { label: "Overnight stay at hotel in Manali" },
            ],
          },
          {
            label: "Day 5: 12th December, 2024",
            location: "",
            description: [
              {
                label: "Manali Sightseeing.",
              },
              { label: "Overnight stay at hotel in Manali." },
            ],
          },
          {
            label: "Day 6: 13th December, 2024",
            location: "",
            description: [
              {
                label: "Manali Sightseeing.",
              },
              { label: "Overnight stay at hotel in Manali." },
            ],
          },
          {
            label: "Day 7: 14th December, 2024",
            location: "",
            description: [
              {
                label: "Manali to Dharmshala. (Kullu)",
              },
              { label: "Overnight stay at hotel in Dharmshala." },
            ],
          },
          {
            label: "Day 8: 15th December, 2024",
            location: "",
            description: [
              {
                label: "Dharmshala Sightseeing.",
              },
              {
                label: "Overnight stay at hotel in Dharmshala.",
              },
            ],
          },
          {
            label: "Day 9: 16th December, 2024",
            location: "",
            description: [
              {
                label: "Dharmshala Sightseeing.",
              },
              { label: "After Dinner Night Journey to Ludhiana." },
            ],
          },
          {
            label: "Day 10: 17th December, 2024",
            location: "",
            description: [
              {
                label: "Ludhiana (7.30am) to Aurangabad.",
              },
            ],
          },
          {
            label: "Day 11: 18th December, 2024",
            location: "",
            description: [
              {
                label: "Arrive at Aurangabad (9.45am)",
              },
              { label: "Tour complete." },
            ],
          },
        ],
        description: {
          first:
            "The Buddhist Circuit is a trail of the sacred footsteps of Lord Buddha and important places of his life and teachings.",

          second:
            "The Buddhist circuit is a route that follows in the footsteps of the Buddha from Lumbini in Nepal where he was born, through Bodhgaya, Bihar in India where he attained enlightenment, to Sarnath and Kushinagar in Uttar Pradesh in India, where he gave his first teachings and his final resting place.",
        },
      },
      {
        id: 2,
        label: "ANDHRA PRADESH",
        img: "ANDHRA_PRADESH.jpg",
        title: "ANDHRA PRADESH",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 3,
        label: "GOA",
        img: "GOA.jpg",
        title: "GOA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 4,
        label: "GUJRAT",
        img: "GUJRAT.jpg",
        title: "GUJRAT",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 6,
        label: "KARNATAKA",
        img: "KARNATAKA.jpg",
        title: "KARNATAKA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 7,
        label: "KASHMIR",
        img: "KASHMIR.jpg",
        title: "KASHMIR",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 8,
        label: "KERALA",
        img: "KERALA.jpg",
        title: "KERALA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 9,
        label: "LEH LADAKH",
        img: "LEH_LADAKH.jpg",
        title: "LEH LADAKH",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 10,
        label: "MADHYA PRADESH",
        img: "MADHYA_PRADESH.jpg",
        title: "MADHYA PRADESH",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 11,
        label: "MAHARASHTRA",
        img: "MAHARASHTRA.jpg",
        title: "MAHARASHTRA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 12,
        label: "NORTH EAST",
        img: "NORTH_EAST.jpg",
        title: "NORTH EAST",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 13,
        label: "ODISHA",
        img: "ODISHA.jpg",
        title: "ODISHA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 14,
        label: "RAJASTHAN",
        img: "RAJASTHAN.jpg",
        title: "RAJASTHAN",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 15,
        label: "SIKKIM",
        img: "SIKKIM.jpg",
        title: "SIKKIM",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 16,
        label: "TAMILNADU",
        img: "TAMILNADU.jpg",
        title: "TAMILNADU",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 17,
        label: "TELANGANA",
        img: "TELANGANA.jpg",
        title: "TELANGANA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 18,
        label: "UTTAR PRADESH",
        img: "UTTAR_PRADESH.jpg",
        title: "UTTAR PRADESH",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 19,
        label: "UTTARAKHAND",
        img: "UTTARAKHAND.jpg",
        title: "UTTARAKHAND",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 20,
        label: "WEST BENGAL",
        img: "WEST_BENGAL.jpg",
        title: "WEST BENGAL",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
    ],
    description: {
      first: "",

      second: "",
    },
  },
  {
    id: 3,
    label: "INTERNATIONAL TOURS",
    category: "INTERNATIONAL TOURS",
    img: "SINGAPORE.jpg",
    packagesTitle: "11 packages",
    onHomePage: true,
    title: "INTERNATIONAL TOURS",
    subTitle: "The Best Destination",
    isVisible: true,
    isComming: true,
    packages: [
      {
        id: 1,
        label: "AFRICA",
        img: "AFRICA.jpg",
        title: "AFRICA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 2,
        label: "AUSTRALIA",
        img: "AUSTRALIA.jpg",
        title: "AUSTRALIA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 3,
        label: "BHUTAN",
        img: "BHUTAN.jpg",
        title: "BHUTAN",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 4,
        label: "CHINA.jpg",
        img: "CHINA.jpg",
        title: "CHINA.jpg",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 5,
        label: "EUROPE",
        img: "EUROPE.jpg",
        title: "EUROPE",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 6,
        label: "INDONESIA",
        img: "INDONESIA.jpg",
        title: "INDONESIA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 7,
        label: "JAPAN",
        img: "JAPAN.jpg",
        title: "JAPAN",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 8,
        label: "MALAYSIA",
        img: "MALAYSIA.jpg",
        title: "MALAYSIA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 9,
        label: "MALDIVES",
        img: "MALDIVES.jpg",
        title: "MALDIVES",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 10,
        label: "MAURITIUS",
        img: "MAURITIUS.jpg",
        title: "MAURITIUS",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 11,
        label: "MIDDLE EAST",
        img: "MIDDLE EAST.jpg",
        title: "MIDDLE EAST",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 12,
        label: "MYANMAR",
        img: "MYANMAR.jpg",
        title: "MYANMAR",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 13,
        label: "NEPAL",
        img: "NEPAL.jpg",
        title: "NEPAL",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 14,
        label: "SEYCHELLES",
        img: "SEYCHELLES.jpg",
        title: "SEYCHELLES",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 15,
        label: "SINGAPORE",
        img: "SINGAPORE.jpg",
        title: "SINGAPORE",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 16,
        label: "SRI LANKA",
        img: "SRI LANKA.jpg",
        title: "SRI LANKA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 17,
        label: "THAILAND",
        img: "THAILAND.jpg",
        title: "THAILAND",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 18,
        label: "UAE",
        img: "UAE.jpg",
        title: "UAE",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 19,
        label: "USA",
        img: "USA.jpg",
        title: "USA",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
    ],
    description: {
      first: "",

      second: "",
    },
  },
  {
    id: 4,
    label: "SPECIALITY TOURS",
    category: "SPECIALITY TOURS",
    img: "Youth Special.jpg",
    packagesTitle: "5 packages",
    onHomePage: true,
    title: "SPECIALITY TOURS",
    subTitle: "The Best Destination",
    isVisible: true,
    isComming: true,
    packages: [
      {
        id: 1,
        label: "ADVENTURE",
        img: "Adventure.jpg",
        title: "ADVENTURE",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 2,
        label: "HONEYMOON SPECIAL",
        img: "Honeymoon Cover.jpg",
        title: "HONEYMOON SPECIAL",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 4,
        label: "YOUTH SPECIAL",
        img: "Youth Special.jpg",
        title: "YOUTH SPECIAL",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 5,
        label: "SECOND INNINGS",
        img: "Second Innings.jpg",
        title: "SECOND INNINGS",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
      {
        id: 6,
        label: "LADIES SPECIAL",
        img: "Ladies Special.jpg",
        title: "LADIES SPECIAL",
        subTitle: "The Best Destination",
        date: "01-Sep-2024",
        endDate: "8 November 2024",
        duration: "3 Days / 4 Nights",
        downloadLink: "",
        location: "location",
        price: "20,000",
        productCode: "000000",
        note: "",
        isCstomized: true,
        cancellationPolicy: [],
        bookingPolicy: [],
        exclusion: [],
        isVisible: true,
        isComming: true,
        inclusions: [
          { label: "3 Breakfast" },
          { label: "Travaling by Train/Plane" },
          { label: "Cruise Party" },
          { label: "Party With Dinner" },
        ],
        dayByDay: [
          {
            label: "DAY 1 - Departure at the airport and arrival at the hotel",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 2 - Visit the main museums and lunch included",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
          {
            label: "DAY 3 - Excursion in the natural oasis and picnic",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          },
        ],
        description: {
          first:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

          second:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      },
    ],
    description: {
      first: "",

      second: "",
    },
  },
  {
    id: 5,
    label: "WEEKEND TREKS/TOUR",
    category: "WEEKEND TOUR",
    img: "KALSUBAI.jpg",
    packagesTitle: "13 packages",
    onHomePage: true,
    title: "WEEKEND TREKS/TOUR",
    subTitle: "The Best Destination",
    isVisible: true,
    isComming: false,
    packages: [
      {
        id: 1,
        label: "AJANTA",
        img: "AJANTA.jpg",
        title: "AJANTA",
        subTitle: "Adventure Freak",
        date: "",
        endDate: "",
        duration: "1 Day",
        downloadLink: "",
        location: "Aurangabad",
        price: "",
        productCode: "AJANTA012022",
        isVisible: true,
        isComming: false,
        isCstomized: true,
        note: [
          {
            label: "Package cost is available for Indian Nationals only",
          },
        ],
        cancellationPolicy: [],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        inclusions: [
          {
            label: "Travelling",
          },
          { label: "Breakfast" },
          { label: "Entry tickets" },
          { label: "Guide" },
          { label: "Sightseeing" },
          { label: "Lunch" },
        ],
        description: {
          first:
            "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

          second:
            "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
        },
      },
      {
        id: 2,
        label: "Ellora & Daulatabad fort",
        img: "ELLORA.jpg",
        title: "Ellora & Daulatabad fort",
        subTitle: "Adventure Freak",
        date: "",
        endDate: "",
        duration: "1 Day",
        downloadLink: "",
        location: "Aurangabad",
        price: "",
        productCode: "ELLORA012022",
        isVisible: true,
        isComming: false,
        isCstomized: true,
        note: [
          {
            label: "Package cost is available for Indian Nationals only",
          },
        ],

        cancellationPolicy: [],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        inclusions: [
          {
            label: "Travelling",
          },
          { label: "Breakfast" },
          { label: "Entry tickets" },
          { label: "Guide" },
          { label: "Sightseeing" },
          { label: "Lunch" },
        ],
        description: {
          first:
            "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

          second:
            "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
        },
      },
      {
        id: 3,
        label: "Bhandardara",
        img: "BHANDARDARA.jpg",
        title: "Bhandardara",
        subTitle: "Adventure Freak",
        date: "",
        endDate: "",
        duration: "1 Night & 1 Day",
        downloadLink: "",
        location: "Aurangabad",
        price: "",
        productCode: "BHANDARDARA012022",
        isVisible: true,
        isComming: false,
        isCstomized: true,
        note: [
          {
            label: "Package cost is available for Indian Nationals only",
          },
        ],

        cancellationPolicy: [],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        inclusions: [
          {
            label: "Travelling",
          },
          { label: "Breakfast" },
          { label: "Entry tickets" },
          { label: "Guide" },
          { label: "Sightseeing" },
          { label: "Lunch" },
          { label: "Dinner" },
        ],
        description: {
          first:
            "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

          second:
            "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
        },
      },
      {
        id: 4,
        label: "Kalsubai",
        img: "KALSUBAI.jpg",
        title: "Kalsubai",
        subTitle: "Adventure Freak",
        date: "",
        endDate: "",
        duration: "1 Night & 1 Day",
        downloadLink: "",
        location: "Aurangabad",
        price: "",
        productCode: "KALSUBAI012022",
        isVisible: true,
        isComming: false,
        isCstomized: true,
        note: [
          {
            label: "Package cost is available for Indian Nationals only",
          },
        ],

        cancellationPolicy: [],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        inclusions: [
          {
            label: "Travelling",
          },
          { label: "Breakfast" },
          { label: "Entry tickets" },
          { label: "Guide" },
          { label: "Sightseeing" },
          { label: "Lunch" },
          { label: "Dinner" },
        ],
        description: {
          first:
            "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

          second:
            "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
        },
      },
      {
        id: 5,
        label: "Ratangad",
        img: "RATANGAD.jpg",
        title: "Ratangad",
        subTitle: "Adventure Freak",
        date: "",
        endDate: "",
        duration: "1 Night & 1 Day",
        downloadLink: "",
        location: "Aurangabad",
        price: "",
        productCode: "RATANGAD012022",
        isVisible: true,
        isComming: false,
        isCstomized: true,
        note: [
          {
            label: "Package cost is available for Indian Nationals only",
          },
        ],

        cancellationPolicy: [],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        inclusions: [
          {
            label: "Travelling",
          },
          { label: "Breakfast" },
          { label: "Entry tickets" },
          { label: "Guide" },
          { label: "Sightseeing" },
          { label: "Lunch" },
          { label: "Dinner" },
        ],
        description: {
          first:
            "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

          second:
            "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
        },
      },
      {
        id: 6,
        label: "Harishchandragad",
        img: "HARISHCHANDRAGAD.jpg",
        title: "Harishchandragad",
        subTitle: "Adventure Freak",
        date: "",
        endDate: "",
        duration: "1 Night & 1 Day",
        downloadLink: "",
        location: "Aurangabad",
        price: "",
        productCode: "HARISHCHANDRAGAD012022",
        isVisible: true,
        isComming: false,
        isCstomized: true,
        note: [
          {
            label: "Package cost is available for Indian Nationals only",
          },
        ],

        cancellationPolicy: [],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        inclusions: [
          {
            label: "Travelling",
          },
          { label: "Breakfast" },
          { label: "Entry tickets" },
          { label: "Guide" },
          { label: "Sightseeing" },
          { label: "Lunch" },
          { label: "Dinner" },
        ],
        description: {
          first:
            "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

          second:
            "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
        },
      },
      {
        id: 7,
        label: "Sandhan Valley",
        img: "SANDHAN VALLEY.jpg",
        title: "Sandhan Valley",
        subTitle: "Adventure Freak",
        date: "",
        endDate: "",
        duration: "1 Night & 1 Day",
        downloadLink: "",
        location: "Aurangabad",
        price: "",
        productCode: "SANDHANVALLEY012022",
        isVisible: true,
        isComming: false,
        isCstomized: true,
        note: [
          {
            label: "Package cost is available for Indian Nationals only",
          },
        ],

        cancellationPolicy: [],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        inclusions: [
          {
            label: "Travelling",
          },
          { label: "Breakfast" },
          { label: "Entry tickets" },
          { label: "Guide" },
          { label: "Sightseeing" },
          { label: "Lunch" },
          { label: "Dinner" },
        ],
        description: {
          first:
            "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

          second:
            "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
        },
      },
      {
        id: 8,
        label: "Harihargad",
        img: "HARIHARGAD.jpg",
        title: "Harihargad",
        subTitle: "Adventure Freak",
        date: "",
        endDate: "",
        duration: "1 Night & 1 Day",
        downloadLink: "",
        location: "Aurangabad",
        price: "",
        productCode: "HARIHARGAD012022",
        isVisible: true,
        isComming: false,
        isCstomized: true,
        note: [
          {
            label: "Package cost is available for Indian Nationals only",
          },
        ],

        cancellationPolicy: [],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        inclusions: [
          {
            label: "Travelling",
          },
          { label: "Breakfast" },
          { label: "Entry tickets" },
          { label: "Guide" },
          { label: "Sightseeing" },
          { label: "Lunch" },
          { label: "Dinner" },
        ],
        description: {
          first:
            "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

          second:
            "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
        },
      },
      {
        id: 9,
        label: "Jivdhangad",
        img: "JIVDHANGAD.jpg",
        title: "Jivdhangad",
        subTitle: "Adventure Freak",
        date: "",
        endDate: "",
        duration: "1 Night & 1 Day",
        downloadLink: "",
        location: "Aurangabad",
        price: "",
        productCode: "JIVDHANGAD012022",
        isVisible: true,
        isComming: false,
        isCstomized: true,
        note: [
          {
            label: "Package cost is available for Indian Nationals only",
          },
        ],

        cancellationPolicy: [],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        inclusions: [
          {
            label: "Travelling",
          },
          { label: "Breakfast" },
          { label: "Entry tickets" },
          { label: "Guide" },
          { label: "Sightseeing" },
          { label: "Lunch" },
          { label: "Dinner" },
        ],
        description: {
          first:
            "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

          second:
            "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
        },
      },
      {
        id: 10,
        label: "Devkund",
        img: "DEVKUND.jpg",
        title: "Devkund",
        subTitle: "Adventure Freak",
        date: "",
        endDate: "",
        duration: "1 Night & 1 Day",
        downloadLink: "",
        location: "Aurangabad",
        price: "",
        productCode: "DEVKUND012022",
        isVisible: true,
        isComming: false,
        isCstomized: true,
        note: [
          {
            label: "Package cost is available for Indian Nationals only",
          },
        ],

        cancellationPolicy: [],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        inclusions: [
          {
            label: "Travelling",
          },
          { label: "Breakfast" },
          { label: "Entry tickets" },
          { label: "Guide" },
          { label: "Sightseeing" },
          { label: "Lunch" },
          { label: "Dinner" },
        ],
        description: {
          first:
            "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

          second:
            "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
        },
      },
      {
        id: 11,
        label: "Korigad",
        img: "KORIGAD.jpg",
        title: "Korigad",
        subTitle: "Adventure Freak",
        date: "",
        endDate: "",
        duration: "1 Night & 1 Day",
        downloadLink: "",
        location: "Aurangabad",
        price: "",
        productCode: "KORIGAD012022",
        isVisible: true,
        isComming: false,
        isCstomized: true,
        note: [
          {
            label: "Package cost is available for Indian Nationals only",
          },
        ],

        cancellationPolicy: [],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        inclusions: [
          {
            label: "Travelling",
          },
          { label: "Breakfast" },
          { label: "Entry tickets" },
          { label: "Guide" },
          { label: "Sightseeing" },
          { label: "Lunch" },
          { label: "Dinner" },
        ],
        description: {
          first:
            "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

          second:
            "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
        },
      },
      {
        id: 12,
        label: "Visapur Fort",
        img: "VISAPURGAD.jpg",
        title: "Visapur Fort",
        subTitle: "Adventure Freak",
        date: "",
        endDate: "",
        duration: "1 Night & 1 Day",
        downloadLink: "",
        location: "Aurangabad",
        price: "",
        productCode: "VISAPURGAD012022",
        isVisible: true,
        isComming: false,
        isCstomized: true,
        note: [
          {
            label: "Package cost is available for Indian Nationals only",
          },
        ],

        cancellationPolicy: [],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        inclusions: [
          {
            label: "Travelling",
          },
          { label: "Breakfast" },
          { label: "Entry tickets" },
          { label: "Guide" },
          { label: "Sightseeing" },
          { label: "Lunch" },
          { label: "Dinner" },
        ],
        description: {
          first:
            "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

          second:
            "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
        },
      },
      {
        id: 13,
        label: "Mahuli Gad",
        img: "MAHULIGAD.jpg",
        title: "Mahuli Gad",
        subTitle: "Adventure Freak",
        date: "",
        endDate: "",
        duration: "1 Night & 1 Day",
        downloadLink: "",
        location: "Aurangabad",
        price: "",
        productCode: "MAHULIGAD012022",
        isVisible: true,
        isComming: false,
        isCstomized: true,
        note: [
          {
            label: "Package cost is available for Indian Nationals only",
          },
        ],

        cancellationPolicy: [],
        bookingPolicy: [
          {
            label:
              "Booking will be confirmed only when full payment of package cost is done",
          },
          {
            label:
              "For Booking, tourist have to fill “Tourist Info Form” & submit it along with the Xerox copy of valid ID Proof (Aadhar Card/Voter ID/Passport/Driving Licence).",
          },
          {
            label:
              "All the payment has to be made in the favour of setyourtour.com through Cash, NEFT Or Any mode of online payment services available.",
          },
        ],
        exclusion: [
          {
            label:
              "Cost of personal nature expenses such as bottled water, laundry, incidentals, porter, tips etc.",
          },
          {
            label:
              "Difference in cost arising due to extra usage of additional services, changes in taxes by Government or political unrest, natural calamities (Land slide/ road blockage) etc. in such case extra will have to be paid on the spot by tourist.",
          },
          {
            label:
              "Cost for anything which is not mentioned in Package Inclusion paragraph.",
          },
        ],
        inclusions: [
          {
            label: "Travelling",
          },
          { label: "Breakfast" },
          { label: "Entry tickets" },
          { label: "Guide" },
          { label: "Sightseeing" },
          { label: "Lunch" },
          { label: "Dinner" },
        ],
        description: {
          first:
            "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

          second:
            "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
        },
      },
    ],
    description: {
      first:
        "If you are an adventure freak, history lover, wildlife enthusiast or wellness seeker, the weekend getaways from Aurangabad, Maharashtra will offer you that which you are looking for.",

      second:
        "Our all-inclusive weekend packages cover the best places to visit in Maharashtra at unbeatable rates in tourist satisfaction & a wide variety of attractive deals for enjoyment.",
    },
  },
  {
    id: 6,
    label: "CUSTOMIZE YOUR TOURS",
    category: "WEEKEND TOUR",
    img: "ELLORA.jpg",
    packagesTitle: "",
    onHomePage: true,
    title: "CUSTOMIZE YOUR TOURS",
    subTitle: "The Best Destination",
    isVisible: true,
    isComming: false,
    packages: [],
    description: {
      first: "",

      second: "",
    },
  },
];
